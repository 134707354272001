<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve">
        <!-- <style type="text/css">
            .st0{fill:none;stroke:#0f3389;stroke-width:3;}
            .st1{fill:#0f3389;}
        </style> -->
        <title>ReservationPeriod_Active</title>
        <desc>Created with Sketch.</desc>
        <g id="ReservationPeriod_Active">
            <circle id="Oval" class="st0" :class="{active: active}" cx="40" cy="40" r="38.5"/>
            <path class="st1" :class="{active: active}" d="M25.6,55.4h5.9v-5.9h-5.9V55.4z M32.9,55.4h6.6v-5.9h-6.6V55.4z M25.6,48.1h5.9v-6.6h-5.9V48.1z M32.9,48.1
                h6.6v-6.6h-6.6V48.1z M25.6,40.2h5.9v-5.9h-5.9V40.2z M40.8,55.4h6.6v-5.9h-6.6V55.4z M32.9,40.2h6.6v-5.9h-6.6V40.2z M48.8,55.4
                h5.9v-5.9h-5.9V55.4z M40.8,48.1h6.6v-6.6h-6.6V48.1z M33.6,30.3v-5.9c0-0.4-0.3-0.7-0.7-0.7h-1.3c-0.4,0-0.7,0.3-0.7,0.7v5.9
                c0,0.4,0.3,0.7,0.7,0.7h1.3C33.3,30.9,33.6,30.6,33.6,30.3z M48.8,48.1h5.9v-6.6h-5.9V48.1z M40.8,40.2h6.6v-5.9h-6.6V40.2z
                M48.8,40.2h5.9v-5.9h-5.9V40.2z M49.4,30.3v-5.9c0-0.4-0.3-0.7-0.7-0.7h-1.3c-0.4,0-0.7,0.3-0.7,0.7v5.9c0,0.4,0.3,0.7,0.7,0.7
                h1.3C49.1,30.9,49.4,30.6,49.4,30.3z M57.4,28.9v26.4c0,1.4-1.2,2.6-2.6,2.6H25.6c-1.4,0-2.6-1.2-2.6-2.6V28.9
                c0-1.4,1.2-2.6,2.6-2.6h2.6v-2c0-1.8,1.5-3.3,3.3-3.3h1.3c1.8,0,3.3,1.5,3.3,3.3v2h7.9v-2c0-1.8,1.5-3.3,3.3-3.3h1.3
                c1.8,0,3.3,1.5,3.3,3.3v2h2.6C56.2,26.3,57.4,27.5,57.4,28.9z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'StepOne',
    props: {
        active: { type: Boolean, default: false }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../global-styles/variables';
.st0 {
    fill:none;
    stroke:#F8F9FA;
    stroke-width:3;

    &.active {
        stroke:$primary-color;
    }
}

.st1 {
    fill:#AEAEAE;

    &.active {
        fill:$primary-color;
    }
}

// .st0 {
//     fill:none;
//     stroke:$primary-color;
//     stroke-width:3;
// }

// .st1{
//     fill:$primary-color;
// }
</style>
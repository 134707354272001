<template>
    <section class="main-content-section">
        <LoadingSpinner v-if="isLoading" />
        <template v-if="order">
            <div class="grid grid-pad">
                <div class="col-1-1 center">
                    <h2 class="page-title">{{orderProcess}}</h2>
                </div>

                <div class="col-1-1">
                    <div class="orderstatus">
                        <div class="orderstatus-wrap">
                            <ul class="steps">
                                <li v-for="(n, i) in 3" :key="`step-${i}`" class="step" :class="{active: programStep === n}">
                                    <span class="svg-wrap">
                                        <StepOne v-if="n === 1" :active="programStep === n" />
                                        <StepTwo v-if="n === 2" :active="programStep === n" />
                                        <StepThree v-if="n === 3" :active="programStep === n" />
                                    </span>
                                    <span class="title">{{getProgramStepText(n)}}</span>
                                    <span v-if="programStep === n" class="subtitle">{{currentStep}}</span>
                                </li>
                            </ul>
                        </div>

                        <div class="orderstatus-text">
                            <div class="text">
                                <h2>{{getProgramStepTitle(programStep)}}</h2>
                                <p>{{getProgramStepDescription(programStep)}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-1-1">
                    <div class="orderstatus-summary textpage clear">
                        <div class="my-order">
                            <div class="row clearfix" id="current-order">
                                <div class="col-1-1">
                                    <h2 class="title">{{myOrder}}: {{order.number}}</h2>
                                </div>
                                <div class="col-3-4">
                                    <strong>{{lastUpdated}}</strong>
                                    <span class="date">{{orderDate}}</span> 
                                </div>
                                <div class="col-1-4 align-right">
                                    <strong style="padding-right: 3rem;">{{total}}</strong>
                                </div>

                                <div class="col-1-1" v-for="line in order.lines" :key="line.id">
                                    <div class="order-line">
                                        <div class="image">
                                            <img :src="line.imageUrl" :alt="line[`name${selectedLanguage}`]">
                                        </div>
                                        <div class="info">
                                            <div class="head">
                                                <div class="name">{{line[`name${selectedLanguage}`]}}</div>
                                            </div>
                                            <div class="info-line">
                                                <div class="key">{{amountLabel}}</div>
                                                <div class="val">{{line.quantity}}</div>
                                            </div>
                                            <!-- <div class="info-line">
                                                <div class="key">Price</div>
                                                <div class="val">€ 3.99</div>
                                            </div>
                                            <div class="info-line">
                                                <div class="key">Voucher code 1</div>
                                                <div class="val">foo1</div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row clearfix" id="order-summary">
                                <div class="col-1-1 no-p">
                                    <div class="info-line">
                                        <div class="key">{{totalAmountOfProducts}}</div>
                                        <div class="val">{{totalQuantity}}</div>
                                    </div>
                                    <!-- <div class="info-line">
                                        <div class="key">Total price</div>
                                        <div class="val">€ 3.99</div>
                                    </div> -->
                                </div>
                            </div>
                        </div>

                        <div class="info-wrap-store">
                            <h2 class="title">{{selectedStoreLabel}}</h2>
                            <div class="flex justify-space-between xs-justify-center">
                                <div class="store-info">
                                    <div><strong>{{selectedStoreData.name}}</strong></div>
                                    <div>{{selectedStoreData.address}}, {{selectedStoreData.zipCode}}, {{selectedStoreData.city}}</div>
                                </div>
                                <div class="button" v-if="programStep !== 3">
                                    <router-link :to="`/place-reservation/select-store?orderId=${this.$route.params.orderId}`" class="btn btn-secondary alternative">{{changeMyStore}}</router-link>
                                </div>
                            </div>
                        </div>

                        <div class="btns-container">
                            <div class="flex justify-center">
                                <button @click="showCancelModal = true" class="btn btn-secondary">{{cancelMyOrder}}</button>
                                <router-link v-if="programStep === 1" :to="`/edit-order/${order.id}`" class="btn btn-primary alternative">{{changeMyOrder}}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmationModal 
                v-show="showCancelModal" 
                @closeModal="showCancelModal = $event"
                @confirmed="cancelOrder()" 
                :title="areYouSure" 
                :text="yourItemsWillNoLongerBeReserved" 
            />
        </template>
        <template v-else>
            <div class="grid grid-pad">
                <div class="col-1-1 center no-order-found">
                    <h2 class="page-title">{{noOrderFound}}</h2>
                    <div class="flex justify-center">
                        <router-link to="/product-catalog" class="btn btn-primary alternative">{{continueShopping}}</router-link>
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import LoadingSpinner from '../../components/ui/LoadingSpinner.vue';
import { mapGetters } from 'vuex';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import StepOne from '../../components/ui/icons/StepOne.vue';
import StepTwo from '../../components/ui/icons/StepTwo.vue';
import StepThree from '../../components/ui/icons/StepThree.vue';

export default {
    name: 'MyOrder',
    components: { ConfirmationModal, LoadingSpinner, StepOne, StepTwo, StepThree },
    data() {
        return {
            isLoading: false,
            showCancelModal: false,
            currentProgramStepTitle: ''
        }
    },

    async created() {
        this.setDocumentTitle();
        this.isLoading = true;
        try {
            this.$store.dispatch('fetchOrder', this.$route.params.orderId);
            this.$store.dispatch('fetchStores');
            this.isLoading = false;
        } catch (error) {
            console.log(error);
            this.isLoading = false;
        }
    },

    updated() {
        this.setDocumentTitle();
    },

    computed: {
        ...mapGetters([
            'metaTitle',
            'selectedLanguage',
            'programStep',
            // Texts
            'orderProcess',
            'reservationPeriod',
            'currentStep',
            'orderIsBeingProcessed',
            'readyForPickUp',
            'youCanMakeChangeOrCancelAReservationAtThisTime',
            'yourOrderIsBeingProcessed',
            'yourOrderIsReadyForPickup',
            'myOrder',
            'lastUpdated',
            'totalAmountOfProducts',
            'total',
            'changeMyStore',
            'changeMyOrder',
            'cancelMyOrder',
            'areYouSure',
            'yourItemsWillNoLongerBeReserved',
            'noOrderFound',
            'continueShopping',
        ]),
        ...mapGetters({
            order: 'getOrder',
            stores: 'getStores',
            // Texts
            selectedStoreLabel: 'selectedStore',
            amountLabel: 'amount',
        }),
        orderDate() {
            const date = new Date(this.order.date);
            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}u`;
        },
        selectedStoreData() {
            return this.$store.getters.storeByName(this.order.store.name);
        },
        totalQuantity() {
            const quantities = this.order.lines.map(line => line.quantity); 
            return quantities.length > 1 ? quantities.reduce((a, b) => a + b) : quantities[0];
        }
    },

    methods: {
        setDocumentTitle() {
            document.title = this.myOrder + this.metaTitle;
        },
        
        getProgramStepText(step) {
            let programText = this.reservationPeriod;
            switch (step) {
                case 1:
                    programText = this.reservationPeriod;
                    break;
                case 2:
                    programText = this.orderIsBeingProcessed;
                    break;
                case 3:
                    programText = this.readyForPickUp;
                    break;
                default:
                    return programText;
            }
            return programText;
        },

        getProgramStepTitle(step) {
            let programTitle = this.reservationPeriod;
            switch (step) {
                case 1:
                    programTitle = this.reservationPeriod;
                    break;
                case 2:
                    programTitle = this.orderIsBeingProcessed;
                    break;
                case 3:
                    programTitle = this.readyForPickUp;
                    break;
                default:
                    return programTitle;
            }
            return programTitle;
        },

        getProgramStepDescription(step) {
            let programDescription = this.youCanMakeChangeOrCancelAReservationAtThisTime;
            switch (step) {
                case 1:
                    programDescription = this.youCanMakeChangeOrCancelAReservationAtThisTime;
                    break;
                case 2:
                    programDescription = this.yourOrderIsBeingProcessed;
                    break;
                case 3:
                    programDescription = this.yourOrderIsReadyForPickup;
                    break;
                default:
                    return programDescription;
            }
            return programDescription;
        },

        cancelOrder() {
            this.$store.dispatch('cancelOrder', this.$route.params.orderId);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';
// order status
.orderstatus {
    margin-top: 1.5rem;
}

.orderstatus-wrap {
    background: $white;
    display: flex;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    
    .steps {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        
        li {
            width: 33.33%;
            padding: 5rem 1rem 3rem;

            &:first-of-type {
                border-right: 3px solid #eceff2;
            }

            &:last-of-type {
                border-left: 3px solid #eceff2;
            }

            .svg-wrap {
                display: block;
                max-width: 100%;
                width: 8rem;
                margin: 0 auto 1rem auto;
            }

            .title {
                font-size: 1.8rem;
                font-weight: 300;
                text-align: center;
                display: block;
            }

            .subtitle {
                font-size: 1.4rem;
                font-weight: 300;
                text-align: center;
                display: block;
                margin: .5rem 0 0 0;
                text-transform: uppercase;
                visibility: hidden;
            }

            &.active .subtitle {
                visibility: visible;
                color: $primary-color;
            }
        }
    }
}

.orderstatus-info {
    margin-top: 3rem;
    
    .info-wrap {
        float: left;
        width: 50%;
        font-weight: 300;
        font-size: 18px;
        line-height: 32px;
        
        span {
            display: block;
        }
    }
}

.orderstatus-text {
    background: $white;
    margin-top: 3px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 4rem;

    .text {
        h2 {
            font-size: 2.4rem;
            font-weight: normal;
            margin: 0 0 1rem 0;
        }

        p {
            font-size: 1.8rem;
            font-weight: 300;
        }
    }
}

.orderstatus-summary {
    margin-top: 3rem;
    margin-bottom: 10rem;
    
    .title {
        font-size: 3.2rem;
        font-weight: 300;
    }

    .new-order {
        margin-top: 0;
    }
}

.info-wrap-store {
    display: flex;
    flex-direction: column;
    border: 1px solid #e8ecf1;
    background-color: $white;
    border-radius: 12px;
    padding: 3rem;
    margin: 0 0 4rem 0;
    
    .title {
        font-size: 2.4rem;
        font-weight: 300;
        margin-bottom: 1rem;
    }
}


.text.list ul {
    display: flex;
    justify-content: center;
    flex-direction: column;
    
    li {
        position: relative;
        display: inline-block;
        
        span {
            position: relative;
            display: inline-block;
            
            &::before {
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: $offblack;
                display: block;
                position: absolute;
                left: -12px;
                top: 8px;
            }
        }
    }

}

// summary

.textpage {
    border-radius: 8px;
    box-shadow: 0 10px 20px #d4dee8;
    padding: 6rem;
    background: $white;
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 300;

    .date {
        margin-left: 1rem;
    }
}

.my-order #extra-order, .my-order #locked-products {
    margin-top: 4rem;
}

.my-order #extra-order [class*='col-']:last-of-type .order-line {
    border-bottom: none;
}

.my-order #extra-order .title {
     margin: 0;
}

.my-order .title {
    margin: 0 0 2rem 0;
}

.my-order .align-right {
    text-align: right;
}

.editable .order-line {
    border: 2px solid $primary-color;
    border-radius: 8px;
    margin-top: 1rem;
}

#locked-products, #locked-products + #order-summary {
    margin-top: 4rem;
}

#locked-products .editable .order-line {
    position: relative;
    overflow: hidden;
    
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 3;        
    }
}

.order-line {
    display: flex;
    padding: 3rem 3rem 3rem 0;
    border-bottom: 1px solid #eceff2;
    
    .image img {
        width: 100%;
        max-width: 13rem;
        display: block;
    }

    .info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        
        .head {
            align-items: center;
            margin-bottom: .5rem;
            
            .name {
                font-size: 2.4rem;
            }
        }
        
        .head,
        .info-line {
            display: flex;
            justify-content: space-between;
        }

        .info-line {
            font-size: 1.6rem;
            line-height: normal;
            padding: .2rem 0;
            align-items: center;
        }
    }

}

#order-summary {
    border-top: 3px solid $primary-color;
    padding: 2rem 3rem 4rem 3rem;
    
    .info-line {
        display: flex;
        justify-content: space-between;
        padding: .2rem 0;
    }
}

.table {
    th {
        font-size: 1.8rem;
        font-weight: 300;
        padding: 2rem 0;
        
        strong {
            font-weight: 400;
        }
    }

    td {
        border-bottom: 1px solid #eceff2;
        padding: 1.5rem 0;
        font-size: 1.6rem;
        font-weight: 300;
    
        &.td-products {
            padding-left: 2rem;
        }
    }
}
    th.td-image,
    td.td-image {
        width: 10rem !important;
        text-align: left;
        vertical-align: top;
    }
            
    td.td-image img {
        max-width: 10rem;
    }
            
    .th-amount {
        text-align: right;
    }
            
    .td-amount {
        text-align: center;
    }

.table .no-border td,
.table .striped td {
    border-bottom: none;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.table .striped {
    border-top: 3px solid $primary-color;
}

    .table .striped td {
        padding-top: 3rem;
    }


.table td.td-amount {
    text-align: right;
}

.order-costs-wrap {
    background: #eceff2;
    padding: 3rem;
    border-radius: 8px;
    margin: 0 0 3rem 0;
}

    .order-costs-wrap .title {
        font-weight: 300;
        font-size: 2.6rem;
        margin: 0 0 1rem 0;
    }

.order-costs {
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: 1.6rem;
    font-weight: 300;
    list-style: none;
}

    .order-costs.no-p {
        padding: 0;
    }

    .order-costs li {
        display: block;
        width: 100%;
        padding: .7rem 0;
    }

        .order-costs li:not(:last-of-type) {
            border-bottom: 1px solid #cfd8e0;
        }

    .order-costs span {
        display: inline-block;
    }

    .order-costs .right-side {
        float: right;
    }

.order-leaflet {
    position: absolute;
    pointer-events: none;
    left: -90px;
    top: -45px;
}

.btns-container {
    .btn-primary {
        margin-left: 3rem;
    }
}

.no-order-found .btn {
    margin-top: 10rem;
}

@media only screen and (max-width: 768px) {
    .textpage {
        padding: 6rem 3rem;

        .date {
            font-size: 1.6rem;
        }
    }
}

@media only screen and (max-width: 600px) {
    .textpage .title {
        font-size: 2.8rem;
    }

    .info-wrap-store {
        .store-info {
            margin-bottom: 2rem;
        }
    }
}

@media only screen and (max-width: 500px) {
    .textpage {
        padding: 6rem 1rem;

        .title {
            font-size: 2.4rem;
        }

        .date {
            font-size: 1.4rem;
        }
    }

    .order-line {
        padding: 3rem 0;
        
        .image img {
            max-width: 10rem;
        }
    }

    .info-wrap-store {
        text-align: center;
    }
}

@media only screen and (max-width: 400px) {
    .textpage {
        .date {
            font-size: 1.2rem;
        }
    }
}

@media only screen and (max-width: 462px) {
    .textpage {
        .btn {
            width: 100%;
            text-align: center;
            margin-left: 0;
            margin-bottom: 2rem;
        }
    }
}
</style>